import { type UndecoratedSequence } from '@witmetrics/api-client';
import CollectionListItem from '../CollectionListItem';
import SequenceIcon from '@/icons/SequenceIcon';
import { useToggle } from '@/hooks/useToggle';
import SequenceViewerDialog from '@/dialogs/SequenceViewerDialog';
import SequenceDialog from '@/dialogs/SequenceDialog';
import DeleteSequenceDialog from '@/dialogs/DeleteSequenceDialog';
import ShareSequenceDialog from '@/dialogs/ShareSequenceDialog';

export type SequenceListItemProps = {
  sequence: UndecoratedSequence;
  onDelete?: (sequenceID: number) => void;
};

export default function SequenceListItem({
  sequence,
  onDelete,
}: SequenceListItemProps) {
  const [isShareDialogOpen, toggleShareDialog] = useToggle(false);
  const [isPreviewDialogOpen, togglePreviewDialog] = useToggle(false);
  const [isEditDialogOpen, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle(false);

  return (
    <>
      <CollectionListItem
        name={sequence.name}
        description={sequence.description}
        updatedAt={sequence.updatedAt}
        renderIcon={(iconClassName) => (
          <SequenceIcon className={iconClassName} />
        )}
        onShare={() => toggleShareDialog(true)}
        onView={() => togglePreviewDialog(true)}
        onOpen={() => togglePreviewDialog(true)}
        onEdit={() => toggleEditDialog(true)}
        onDelete={() => toggleDeleteDialog(true)}
      />
      {isShareDialogOpen && (
        <ShareSequenceDialog
          sequenceID={sequence.id}
          onClose={() => toggleShareDialog(false)}
        />
      )}
      {isPreviewDialogOpen && (
        <SequenceViewerDialog
          sequenceID={sequence.id}
          onDelete={() => onDelete && onDelete(sequence.id)}
          onClose={() => togglePreviewDialog(false)}
        />
      )}
      {isEditDialogOpen && (
        <SequenceDialog
          sequenceID={sequence.id}
          onClose={() => toggleEditDialog(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteSequenceDialog
          sequenceID={sequence.id}
          onDelete={() => onDelete && onDelete(sequence.id)}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
    </>
  );
}

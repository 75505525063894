import { type ReactNode, useRef } from 'react';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@/components/Tooltip';
import Divider from '@/components/Divider';
import { renderDateWithTime } from '@/utils/time';
import { useDateFormatPreference } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { isBlank } from '@/utils/strings';
import OptionsIcon from '@/icons/OptionsIcon';
import TypeIcon from './TypeIcon';
import Info from './Info';
import OptionsMenu from './OptionsMenu';
import { classes, getNameString, styles } from './utils';

export type CollectionListItemProps = {
  name: string;
  label?: string;
  description?: string | null;
  size?: string | null;
  updatedAt: string;
  renderIcon: (className: string) => ReactNode;
  onDownload?: () => void;
  onShare?: () => void;
  onView: () => void;
  onOpen: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

export default function CollectionListItem({
  name,
  label,
  description,
  updatedAt,
  size,
  renderIcon,
  onDownload,
  onShare,
  onView,
  onOpen,
  onEdit,
  onDelete,
}: CollectionListItemProps) {
  const menuToggleRef = useRef(null);
  const dateFormat = useDateFormatPreference();
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const renderName = () => {
    const { label, isTruncated } = getNameString(name);
    const content = <div className={classes.name}>{label}</div>;
    if (isTruncated) {
      return <Tooltip title={name}>{content}</Tooltip>;
    } else {
      return content;
    }
  };

  return (
    <ListItem sx={styles.listItem} className={classes.wrapper}>
      <div className={classes.leftContent}>
        <TypeIcon label={label} renderIcon={renderIcon} onClick={onOpen} />
        <div>
          <div className={classes.nameWrapper}>
            <div className={classes.name} onClick={onOpen}>
              {renderName()}
            </div>
            {!isBlank(description) && <Info description={description} />}
          </div>
          <div className={classes.detailsWrapper}>
            <div>{renderDateWithTime(updatedAt, dateFormat)}</div>
            {size && (
              <>
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
                <div>{size}</div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <OptionsIcon
          ref={menuToggleRef}
          className={classes.optionsIcon}
          onClick={() => toggleMenu()}
        />
        {isMenuOpen && (
          <OptionsMenu
            open
            anchorTo={menuToggleRef.current}
            onDownload={onDownload}
            onShare={onShare}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
            onClose={() => toggleMenu(false)}
          />
        )}
      </div>
    </ListItem>
  );
}

import { useEffect, useRef, useState } from 'react';
import type {
  DecoratedSequenceStep,
  UndecoratedSequenceStep,
} from '@witmetrics/api-client';
import Dialog from '@/components/Dialog';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  downloadFileVersion,
  fetchFileVersion,
  isScan,
  isViewableNonScan,
} from '@/utils/files';
import { useDispatch, useSequence, useSequenceSteps } from '@/store/useStore';
import { setSequenceSteps as dispatchSetSequenceSteps } from '@/store/slices/sequenceStepsSlice';
import { setFiles as dispatchSetFiles } from '@/store/slices/filesSlice';
import { setSequenceStepFiles as dispatchSetSequenceStepFiles } from '@/store/slices/sequenceStepFilesSlice';
import { setFileVersions as dispatchSetFileVersions } from '@/store/slices/fileVersionsSlice';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { showErrorToaster } from '@/utils/toasters';
import { loadScan } from '@/utils/scans';
import ViewerHeader from '@/components/ViewerHeader';
import StepViewer from '@/components/StepViewer';
import SequenceIcon from '@/icons/SequenceIcon';
import SceneProvider from '@/providers/SceneProvider';
import ShareSequenceDialog from '@/dialogs/ShareSequenceDialog';
import SequenceDialog from '@/dialogs/SequenceDialog';
import DeleteSequenceDialog from '@/dialogs/DeleteSequenceDialog';
import SequenceSlider from '@/components/SequenceSlider';
import LoadingProgress from '@/components/LoadingProgress';
import { mapToKey } from '@/utils/arrays';
import { UploadedFile } from '@/types/files';
import {
  addFileToStep,
  classes,
  fetchSequenceSteps,
  getOrderedIDs,
} from './utils';

export type SequenceViewerDialogProps = {
  sequenceID: number;
  onDelete?: () => void;
  onClose: () => void;
};

export default function SequenceViewerDialog({
  sequenceID,
  onDelete,
  onClose,
}: SequenceViewerDialogProps) {
  const stepsRef = useRef<UndecoratedSequenceStep[]>([]);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const { loadedFiles, addLoadedFile } = useLoadedFiles();
  const [activeStepID, setActiveStepID] = useState<number | null>(null);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [isLoadingFiles, toggleLoadingFiles] = useToggle(false);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isShareDialogOpen, toggleShareDialog] = useToggle(false);
  const [isEditDialogOpen, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle(false);
  const sequence = useSequence(sequenceID);
  const steps = useSequenceSteps(sequenceID);

  useEffect(() => {
    fetchData();
  }, [sequenceID]);

  useEffect(() => {
    // If a step has been removed, update activeStepID
    if (steps.length < stepsRef.current.length) {
      const stepIDs = getOrderedIDs(steps);
      const oldIDs = getOrderedIDs(stepsRef.current);
      if (stepIDs.length === 0) setActiveStepID(null);
      else if (activeStepID !== null && !stepIDs.includes(activeStepID)) {
        // Current step was removed, find the next step
        const index = oldIDs.indexOf(activeStepID);
        if (index < steps.length) setActiveStepID(stepIDs[index]);
        else setActiveStepID(stepIDs[steps.length - 1]);
      }
    }
    stepsRef.current = steps;
  }, [steps, activeStepID]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchSequenceSteps(sequenceID);
      dispatch(dispatchSetSequenceSteps(data.steps));
      dispatch(dispatchSetFiles(data.files));
      if (data.files.length > 0) {
        await handleOpenFiles(data.files.map((f) => f.id));
      }
      // Only set activeStepID once all files finished loading
      if (data.steps.length > 0) setActiveStepID(data.steps[0].id);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching file sequence');
    }
  };

  const handleOpenFiles = async (fileIDs: number[]) => {
    try {
      let fileIDsToLoad = fileIDs.filter((id) => !loadedFiles[id]);
      if (fileIDsToLoad.length === 0) return;
      toggleLoadingFiles(true);
      setLoadingProgress(0);
      let count = 0;
      const total = fileIDsToLoad.length;
      for (const fileID of fileIDsToLoad) {
        const fileVersion = await fetchFileVersion(fileID);
        if (!fileVersion) {
          showErrorToaster('File version not found');
          continue;
        }
        const arrayBuffer = await downloadFileVersion(fileVersion.id);
        if (isScan(fileVersion.name)) {
          const mesh = loadScan(fileVersion.name, arrayBuffer);
          if (!mesh) {
            showErrorToaster('Unrecognized scan format');
            continue;
          }
          addLoadedFile(fileID, mesh);
        } else if (isViewableNonScan(fileVersion.name)) {
          addLoadedFile(fileID, URL.createObjectURL(new Blob([arrayBuffer])));
        }
        count++;
        setLoadingProgress((val) => Math.max(val, count / total));
      }
      toggleFetchingData(false);
      toggleLoadingFiles(false);
    } catch (err) {
      toggleFetchingData(false);
      toggleLoadingFiles(false);
      onApiError(err, 'Error loading file(s)');
    }
  };

  const handleDeleteSequence = (sequenceID: number) => {
    toggleDeleteDialog(false);
    if (activeStepID === sequenceID) setActiveStepID(null);
    if (onDelete) onDelete();
    onClose();
  };

  const handleAddStep = (step: DecoratedSequenceStep) => {
    setActiveStepID(step.id);
  };

  const handleSelectStep = (stepID: number) => setActiveStepID(stepID);

  const handleSaveFiles = async (fileUploads: UploadedFile[]) => {
    const files = mapToKey(fileUploads, 'file');
    const fileVersions = mapToKey(fileUploads, 'fileVersion');
    const stepFiles = await Promise.all(
      files.map((f) => addFileToStep(activeStepID!, f.id))
    );
    dispatch(dispatchSetFiles(files));
    dispatch(dispatchSetFileVersions(fileVersions));
    dispatch(dispatchSetSequenceStepFiles(stepFiles));
  };

  return (
    <>
      <Dialog className={classes.dialog} onClose={onClose}>
        <ViewerHeader
          label={sequence.name}
          renderIcon={(iconClassName) => (
            <SequenceIcon className={iconClassName} />
          )}
          onShare={() => toggleShareDialog(true)}
          onEdit={() => toggleEditDialog(true)}
          onDelete={() => toggleDeleteDialog(true)}
          onClose={onClose}
        />
        <SceneProvider>
          {isLoadingFiles && <LoadingProgress progress={loadingProgress} />}
          {activeStepID && (
            <StepViewer
              editable
              isLoading={isFetchingData || isLoadingFiles}
              className={classes.viewer}
              sequenceID={sequenceID}
              stepID={activeStepID}
              onSaveFiles={handleSaveFiles}
              onOpenFiles={handleOpenFiles}
            />
          )}
        </SceneProvider>
        <SequenceSlider
          activeStepID={activeStepID}
          steps={steps}
          onSelect={handleSelectStep}
        />
      </Dialog>
      {isShareDialogOpen && (
        <ShareSequenceDialog
          sequenceID={sequenceID}
          onClose={() => toggleShareDialog(false)}
        />
      )}
      {isEditDialogOpen && (
        <SequenceDialog
          sequenceID={sequenceID}
          onClose={() => toggleEditDialog(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteSequenceDialog
          sequenceID={sequenceID}
          onDelete={handleDeleteSequence}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
    </>
  );
}

import type { HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { classes } from './utils';

// https://stackoverflow.com/a/52173021/5839360: TypeScript has a restriction that an interface can only extend a dotted name, so you have to define a type alias
type LiProps = HTMLAttributes<HTMLLIElement>;
export interface MenuOptionProps extends LiProps {
  label: string;
  icon: ReactNode;
  onClick: () => void;
}

export default function MenuOption({
  className = '',
  icon,
  label,
  ...props
}: MenuOptionProps) {
  return (
    <li className={twMerge(classes.item, className)} {...props}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </li>
  );
}

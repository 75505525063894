import List, { type ListProps } from '@mui/material/List';
import SkeletonListItem from '../CollectionListItem/SkeletonListItem';

export interface CollectionListProps extends Omit<ListProps, 'onSelect'> {
  isFetchingData?: boolean;
}

export default function CollectionList({
  isFetchingData,
  children,
  ...props
}: CollectionListProps) {
  return (
    <List {...props}>
      {isFetchingData
        ? [0, 0.2, 0.3].map((delay) => (
            <SkeletonListItem key={delay} delay={delay} />
          ))
        : children}
    </List>
  );
}

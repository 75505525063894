import Menu, { type MenuProps } from '@/components/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddFileIcon from '@/icons/AddFileIcon';
import AddFolderIcon from '@/icons/AddFolderIcon';
import SequenceIcon from '@/icons/SequenceIcon';
import FileInput from '@/components/FileInput';
import DirectoryInput from '@/components/DirectoryInput';
import { type Directory } from '@/types/files';
import { classes } from './utils';

export type NewFileMenuProps = {
  open: boolean;
  disableAddFiles?: boolean;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  onAddFiles: (files: File[]) => void;
  onAddDirectory: (directory: Directory) => void;
  onAddFileCollection: () => void;
  onAddSequence: () => void;
  onClose: () => void;
};

export default function NewFileMenu({
  open,
  disableAddFiles,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onAddFiles,
  onAddDirectory,
  onAddFileCollection,
  onAddSequence,
  onClose,
}: NewFileMenuProps) {
  return (
    <Menu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      <FileInput
        multiple
        disabled={disableAddFiles}
        id="new-files"
        className={classes.input}
        onSelect={onAddFiles}
      />
      <DirectoryInput
        multiple
        id="new-directories"
        className={classes.input}
        onSelect={onAddDirectory}
      />
      {!disableAddFiles && (
        <li className={classes.menuItem} onClick={onAddSequence}>
          <ListItemIcon>
            <SequenceIcon />
          </ListItemIcon>
          <ListItemText>New Sequence</ListItemText>
        </li>
      )}
      {!disableAddFiles && (
        <li className={classes.menuItem}>
          <label htmlFor="new-files" className={classes.inputLabel}>
            <ListItemIcon>
              <AddFileIcon />
            </ListItemIcon>
            <ListItemText>Upload File(s)</ListItemText>
          </label>
        </li>
      )}
      <li className={classes.menuItem}>
        <label htmlFor="new-directories" className={classes.inputLabel}>
          <ListItemIcon>
            <AddFolderIcon />
          </ListItemIcon>
          <ListItemText>Upload File Collection(s)</ListItemText>
        </label>
      </li>
      <li className={classes.menuItem} onClick={onAddFileCollection}>
        <ListItemIcon>
          <AddFolderIcon />
        </ListItemIcon>
        <ListItemText>New File Collection</ListItemText>
      </li>
    </Menu>
  );
}

import { type ChangeEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import { type Directory } from '@/types/files';
import { parseFileInputPaths } from '@/utils/files';
import { classes } from './utils';

export type DirectoryInputProps = {
  id: string;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  onSelect: (directory: Directory) => void;
};

export default function DirectoryInput({
  id,
  multiple = false,
  disabled,
  className,
  onSelect,
}: DirectoryInputProps) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    return onSelect(
      parseFileInputPaths(e.target.files ? Array.from(e.target.files) : [])
    );
  };

  return (
    <input
      id={id}
      multiple={multiple}
      disabled={disabled}
      webkitdirectory="true"
      className={twMerge(classes.input, className)}
      type="file"
      onChange={handleChange}
    />
  );
}

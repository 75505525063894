import { type UndecoratedFile } from '@witmetrics/api-client';
import CollectionListItem from '../CollectionListItem';
import FileIcon from '@/icons/FileIcon';
import { useToggle } from '@/hooks/useToggle';
import { useFileVersionFromFileID } from '@/store/useStore';
import FileInfoDialog from '@/dialogs/FileInfoDialog';
import DeleteFileDialog from '@/dialogs/DeleteFileDialog';
import FileViewerDialog from '@/dialogs/FileViewerDialog';
import ShareFileDialog from '@/dialogs/ShareFileDialog';
import { formatFileSize } from '@/utils/strings';

export type FileListItemProps = {
  file: UndecoratedFile;
  onDownload: (fileID: number) => void;
  onDelete?: (fileID: number) => void;
};

export default function FileListItem({
  file,
  onDownload,
  onDelete,
}: FileListItemProps) {
  const fileVersion = useFileVersionFromFileID(file.id);
  const [isShareDialogOpen, toggleShareDialog] = useToggle(false);
  const [isPreviewDialogOpen, togglePreviewDialog] = useToggle(false);
  const [isEditDialogOpen, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle(false);

  return (
    <>
      <CollectionListItem
        name={file.name}
        description={file.description}
        updatedAt={file.updatedAt}
        size={fileVersion?.size && formatFileSize(parseInt(fileVersion.size))}
        renderIcon={(iconClassName) => <FileIcon className={iconClassName} />}
        onDownload={() => onDownload(file.id)}
        onShare={() => toggleShareDialog(true)}
        onView={() => togglePreviewDialog(true)}
        onOpen={() => togglePreviewDialog(true)}
        onEdit={() => toggleEditDialog(true)}
        onDelete={() => toggleDeleteDialog(true)}
      />
      {isShareDialogOpen && (
        <ShareFileDialog
          fileID={file.id}
          onClose={() => toggleShareDialog(false)}
        />
      )}
      {isPreviewDialogOpen && (
        <FileViewerDialog
          fileID={file.id}
          onDelete={onDelete}
          onClose={() => togglePreviewDialog(false)}
        />
      )}
      {isEditDialogOpen && (
        <FileInfoDialog
          fileID={file.id}
          onClose={() => toggleEditDialog(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteFileDialog
          fileID={file.id}
          onDelete={onDelete}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
    </>
  );
}

import Menu, { MenuDivider, type MenuProps } from '@/components/Menu';
import MenuOption from './MenuOption';
import DownloadIcon from '@/icons/DownloadIcon';
import ShareIcon from '@/icons/ShareIcon';
import ShowIcon from '@/icons/ShowIcon';
import EditIcon from '@/icons/EditIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import { classes } from './utils';

export type OptionsMenuProps = {
  open: boolean;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  onDownload?: () => void;
  onShare?: () => void;
  onView?: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export default function OptionsMenu({
  open,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onDownload,
  onShare,
  onView,
  onEdit,
  onDelete,
  onClose,
}: OptionsMenuProps) {
  const handleSelect = (cb: () => void) => () => {
    cb();
    onClose();
  };
  return (
    <Menu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      {onDownload && (
        <MenuOption
          label="Download"
          icon={<DownloadIcon fontSize="small" />}
          onClick={handleSelect(onDownload)}
        />
      )}
      {onShare && (
        <MenuOption
          label="Share"
          icon={<ShareIcon fontSize="small" />}
          onClick={handleSelect(onShare)}
        />
      )}
      {onView && (
        <MenuOption
          label="View"
          icon={<ShowIcon fontSize="small" />}
          onClick={handleSelect(onView)}
        />
      )}
      <MenuOption
        label="Edit"
        icon={<EditIcon fontSize="small" />}
        onClick={handleSelect(onEdit)}
      />
      <MenuDivider />
      <MenuOption
        label="Delete"
        className={classes.deleteIcon}
        icon={<DeleteIcon className={classes.deleteIcon} fontSize="small" />}
        onClick={handleSelect(onDelete)}
      />
    </Menu>
  );
}

import { type DecoratedFileCollection } from '@witmetrics/api-client';
import CollectionListItem from '../CollectionListItem';
import FolderIcon from '@/icons/FolderIcon';
import { useToggle } from '@/hooks/useToggle';
import FileCollectionViewerDialog from '@/dialogs/FileCollectionViewerDialog';
import FileCollectionDialog from '@/dialogs/FileCollectionDialog';
import DeleteFileCollectionDialog from '@/dialogs/DeleteFileCollectionDialog';
import { getCountLabel } from './utils';

export type FileCollectionListItemProps = {
  fileCollection: DecoratedFileCollection;
  onOpen: (collectionID: number) => void;
  onDownload?: (collectionID: number) => void;
  onDelete?: (collectionID: number) => void;
};

export default function FileCollectionListItem({
  fileCollection,
  onOpen,
  onDownload,
  onDelete,
}: FileCollectionListItemProps) {
  const [isPreviewDialogOpen, togglePreviewDialog] = useToggle(false);
  const [isEditDialogOpen, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle(false);

  return (
    <>
      <CollectionListItem
        name={fileCollection.name}
        description={fileCollection.description}
        size={getCountLabel(fileCollection)}
        updatedAt={fileCollection.updatedAt}
        renderIcon={(iconClassName) => <FolderIcon className={iconClassName} />}
        onView={() => togglePreviewDialog(true)}
        onOpen={() => onOpen(fileCollection.id)}
        onEdit={() => toggleEditDialog(true)}
        onDownload={() => onDownload && onDownload(fileCollection.id)}
        onDelete={() => toggleDeleteDialog(true)}
      />
      {isPreviewDialogOpen && (
        <FileCollectionViewerDialog
          fileCollectionID={fileCollection.id}
          onDelete={() => onDelete && onDelete(fileCollection.id)}
          onClose={() => togglePreviewDialog(false)}
        />
      )}
      {isEditDialogOpen && (
        <FileCollectionDialog
          fileCollectionID={fileCollection.id}
          onClose={() => toggleEditDialog(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteFileCollectionDialog
          fileCollectionID={fileCollection.id}
          onDelete={onDelete}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
    </>
  );
}

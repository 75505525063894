import { type ReactNode } from 'react';
import { classes } from './utils';

export type TypeIconProps = {
  label?: string;
  renderIcon: (className: string) => ReactNode;
  onClick: () => void;
};

export default function TypeIcon({
  label,
  renderIcon,
  onClick,
}: TypeIconProps) {
  return (
    <div className={classes.iconWrapper} onClick={onClick}>
      {renderIcon(classes.fileIcon)}
      {label && <div className={classes.childCount}>{label}</div>}
    </div>
  );
}

'use client';
import { useEffect, useRef } from 'react';
import FileInput from '@/components/FileInput';

export type AddFileInputProps = {
  isSaving: boolean;
  id: string;
  onSelect: (files: File[]) => void;
};

export default function AddFileInput({
  isSaving,
  id,
  onSelect,
}: AddFileInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Automatically open the file dialog when the component first mounts
    if (inputRef.current) inputRef.current.click();
  }, []);

  return (
    <FileInput
      multiple
      ref={inputRef}
      disabled={isSaving}
      id={id}
      onSelect={onSelect}
    />
  );
}

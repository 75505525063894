import { useRef, useState } from 'react';
import { DropResult } from 'react-smooth-dnd';
import type { UndecoratedSequence } from '@witmetrics/api-client';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { addSequence as dispatchAddSequence } from '@/store/slices/sequencesSlice';
import { setSequenceSteps as dispatchSetSequenceSteps } from '@/store/slices/sequenceStepsSlice';
import { addFileCollectionSequence as dispatchAddFileCollectionSequence } from '@/store/slices/fileCollectionSequencesSlice';
import { setFiles as dispatchSetFiles } from '@/store/slices/filesSlice';
import { setFileVersions as dispatchSetFileVersions } from '@/store/slices/fileVersionsSlice';
import { setSequenceStepFiles as dispatchSetSequenceStepFiles } from '@/store/slices/sequenceStepFilesSlice';
import AddIcon from '@/icons/AddIcon';
import AddFileInput from './AddFileInput';
import {
  addToArray,
  arrayMove,
  hasMovedIndex,
  removeFromArray,
} from '@/utils/arrays';
import SelectedFilesList from './SelectedFilesList';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import Title from '@/components/Title';
import Divider from '@/components/Divider';
import {
  addStep,
  buildSelectedFile,
  buildSequenceName,
  classes,
  createNewSequence,
  groupByPosition,
  type GroupedFiles,
  isDisabled,
  removeStep,
  SelectedFile,
} from './utils';

type NewSequenceDialogProps = {
  sequenceID?: number;
  fileCollectionID: number;
  onSave?: (sequence: UndecoratedSequence) => void;
  onClose: () => void;
};

export default function NewSequenceDialog({
  sequenceID,
  fileCollectionID,
  onSave,
  onClose,
}: NewSequenceDialogProps) {
  const dispatch = useDispatch();
  // NOTE: using a ref to handle stale state merging
  const selectedFilesRef = useRef<GroupedFiles>({});
  const activeAccount = useActiveAccount();
  const { onApiError, onToggleUnloadWarning } = useAppState();
  const { addLoadedFiles } = useLoadedFiles();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, toggleSaving] = useToggle(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<GroupedFiles>({});

  const handleClose = () => {
    if (!isSaving) onClose();
  };

  const handleSave = async () => {
    try {
      if (!activeAccount || isSaving) return;
      toggleSaving(true);
      onToggleUnloadWarning(true);
      const data = await createNewSequence(
        {
          name: buildSequenceName(name, selectedFiles),
          description,
          practiceID: activeAccount.accountID,
          fileCollectionID,
          selectedFiles,
        },
        handleChangeFile
      );
      onToggleUnloadWarning(false);
      dispatch(dispatchAddSequence(data.sequence));
      dispatch(dispatchSetSequenceSteps(data.sequenceSteps));
      dispatch(dispatchAddFileCollectionSequence(data.fileCollectionSequence));
      dispatch(dispatchSetFiles(data.files));
      dispatch(dispatchSetFileVersions(data.fileVersions));
      dispatch(dispatchSetSequenceStepFiles(data.sequenceStepFiles));
      addLoadedFiles(data.loadedFiles);
      if (onSave) onSave(data.sequence);
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error creating sequence');
    }
  };

  const updateSelectedFiles = (files: GroupedFiles) => {
    selectedFilesRef.current = files;
    setSelectedFiles(selectedFilesRef.current);
  };

  const handleAddStep = (position: string) => {
    updateSelectedFiles(addStep(parseInt(position), selectedFilesRef.current));
  };

  const handleRemoveStep = (position: string) => {
    updateSelectedFiles(removeStep(position, selectedFilesRef.current));
  };

  const handleSelectStepFiles = (files: File[], position: string) => {
    updateSelectedFiles(
      groupByPosition(
        selectedFilesRef.current,
        files.map((f) => ({
          ...buildSelectedFile(f),
          position: parseInt(position),
        }))
      )
    );
  };

  const handleSelectFiles = (files: File[]) => {
    updateSelectedFiles(
      groupByPosition(selectedFilesRef.current, files.map(buildSelectedFile))
    );
  };

  const handleChangeFile = (selectedFile: SelectedFile) => {
    const key = `${selectedFile.position}`;
    updateSelectedFiles({
      ...selectedFilesRef.current,
      [key]: selectedFilesRef.current[key].map((sf) =>
        sf.uuid === selectedFile.uuid ? selectedFile : sf
      ),
    });
  };

  const handleRemoveFile = (selectedFile: SelectedFile) => {
    const key = `${selectedFile.position}`;
    let files = { ...selectedFilesRef.current };
    const arr = files[key].filter((sf) => sf.uuid !== selectedFile.uuid);
    if (arr.length === 0) delete files[key];
    else files[key] = arr;
    updateSelectedFiles(files);
  };

  const handleMoveFile = (dropResult: DropResult, position: string) => {
    const { removedIndex, addedIndex, payload } = dropResult;
    if (position === 'UNSORTED' || !hasMovedIndex(removedIndex, addedIndex)) {
      return; // Nothing changed or trying to add to the Unsorted group
    }
    let file = { ...payload } as SelectedFile;
    let files = { ...selectedFilesRef.current };
    if (addedIndex !== null && removedIndex !== null) {
      // Item moved within the same step
      files[position] = arrayMove(files[position], removedIndex, addedIndex);
    } else if (addedIndex !== null) {
      // Item was added but not removed
      file.position = parseInt(position);
      files[position] = addToArray(files[position], file, addedIndex);
    } else if (removedIndex !== null) {
      // Item was removed but not added
      files[position] = removeFromArray(files[position], removedIndex);
    }
    updateSelectedFiles(files);
  };

  const renderFileButton = () => {
    return (
      <>
        <label htmlFor="new-sequence-files" className={classes.addButton}>
          <AddIcon className={classes.addIcon} />
          <span>Add file(s)</span>
        </label>
      </>
    );
  };

  const hasFiles = Object.keys(selectedFiles).length > 0;

  return (
    <Dialog className={classes.dialog} onClose={handleClose}>
      <DialogTitle
        title={sequenceID ? 'Edit sequence' : 'New sequence'}
        onClose={handleClose}
      />
      <DialogBody className={classes.body}>
        {hasFiles && (
          <>
            <div className={classes.titleWrapper}>
              <Title>Steps</Title>
              {renderFileButton()}
            </div>
            <Divider className={classes.divider} />
          </>
        )}
        <div className={classes.filesWrapper}>
          {hasFiles ? (
            <SelectedFilesList
              isSaving={isSaving}
              selectedFiles={selectedFiles}
              onAddStep={handleAddStep}
              onRemoveStep={handleRemoveStep}
              onAddFiles={handleSelectStepFiles}
              onMoveFile={handleMoveFile}
              onUpdateFile={handleChangeFile}
              onRemoveFile={handleRemoveFile}
            />
          ) : (
            <div>
              <div className={classes.emptyLabel}>No files selected.</div>
              {renderFileButton()}
            </div>
          )}
        </div>
        <AddFileInput
          isSaving={isSaving}
          id="new-sequence-files"
          onSelect={handleSelectFiles}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isDisabled(selectedFiles)}
        pending={isSaving}
        className={classes.actions}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={handleClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}

import { truncateString } from '@/utils/strings';

export const classes = {
  wrapper:
    'rounded-xl bg-white border border-grey-3 flex justify-between items-center mb-3 py-2',
  leftContent: 'flex justify-start items-center',
  header: 'flex justify-between items-center pl-3 pr-2 pt-3 w-full',
  nameWrapper: 'flex justify-start items-center',
  name: 'text-grey-8 font-medium text-base select-none hover:underline cursor-pointer',
  detailsWrapper:
    'flex justify-start items-center text-sm text-grey-6 select-none',
  divider: 'border-grey-5 mx-2',
  optionsIcon: 'icon-button cursor-pointer text-grey-6',
};

export const styles = {
  listItem: {
    '& .icon-button': {
      opacity: 0,
      transition: 'opacity 0.1s',
    },
    '&:hover .icon-button': {
      opacity: 1,
    },
  },
};

export function getNameString(name: string) {
  const label = truncateString(name, 50);
  return { label, isTruncated: name.length > label.length };
}

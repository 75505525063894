import Skeleton from '@mui/material/Skeleton';
import { classes } from '../utils';
import { styles } from './utils';

export type SkeletonListItemProps = {
  delay?: number;
};

export default function SkeletonListItem({ delay = 0 }: SkeletonListItemProps) {
  return (
    <Skeleton
      sx={styles.skeleton(delay)}
      className={classes.wrapper}
      variant="rectangular"
      animation="wave"
    />
  );
}

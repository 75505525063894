import { twMerge } from 'tailwind-merge';
import { Container, type DropResult } from 'react-smooth-dnd';
import Title from '@/components/Title';
import { formatNumber } from '@/utils/strings';
import StepFilesButton from '../StepFilesButton';
import CloseIcon from '@/icons/CloseIcon';
import StepDivider from './StepDivider';
import ListItem from './ListItem';
import { type GroupedFiles, type SelectedFile } from '../utils';
import { classes, sortByPosition } from './utils';

export type SelectedFilesListProps = {
  isSaving: boolean;
  selectedFiles: GroupedFiles;
  onAddStep: (position: string) => void;
  onRemoveStep: (position: string) => void;
  onAddFiles: (files: File[], position: string) => void;
  onMoveFile: (dropResult: DropResult, position: string) => void;
  onUpdateFile: (selectedFile: SelectedFile) => void;
  onRemoveFile: (selectedFile: SelectedFile) => void;
};

export default function SelectedFilesList({
  isSaving,
  selectedFiles,
  onAddStep,
  onRemoveStep,
  onAddFiles,
  onMoveFile,
  onUpdateFile,
  onRemoveFile,
}: SelectedFilesListProps) {
  return (
    <>
      {sortByPosition(selectedFiles).map((position, index) => (
        <div key={position} className={classes.stepBlock}>
          <div className={classes.stepTitle}>
            <div className={classes.titleWrapper}>
              <Title size="sm" className={classes.title}>
                {position === 'UNSORTED'
                  ? 'Unsorted'
                  : `Step #${formatNumber(index)}`}
              </Title>
              {position !== 'UNSORTED' && (
                <StepFilesButton
                  isSaving={isSaving}
                  position={position}
                  onSelectFiles={onAddFiles}
                />
              )}
            </div>
            {!isSaving && (
              <CloseIcon
                className={twMerge(classes.iconButton, classes.closeIcon)}
                onClick={() => onRemoveStep(position)}
              />
            )}
          </div>
          {/* @ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93 */}
          <Container
            groupName="steps"
            dragHandleSelector=".js-drag-handle"
            lockAxis="y"
            getChildPayload={(index) => selectedFiles[position][index]}
            onDrop={(dropData) => onMoveFile(dropData, position)}>
            {selectedFiles[position].map((sf) => (
              <ListItem
                key={sf.uuid}
                isSaving={isSaving}
                selectedFile={sf}
                onChange={onUpdateFile}
                onRemove={onRemoveFile}
              />
            ))}
          </Container>
          {position !== 'UNSORTED' && (
            <StepDivider onAddStep={() => onAddStep(position)} />
          )}
        </div>
      ))}
    </>
  );
}

import { useState } from 'react';
import type {
  DecoratedFileCollection,
  UndecoratedFile,
  UndecoratedSequence,
} from '@witmetrics/api-client';
import DownloadToaster from '@/components/DownloadToaster';
import { showErrorToaster } from '@/utils/toasters';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import CollectionList from './CollectionList';
import FileListItem from './FileListItem';
import FileCollectionListItem from './FileCollectionListItem';
import SequenceListItem from './SequenceListItem';
import {
  downloadFileCollection,
  fetchFileVersion,
  openFileDownloadLink,
} from '@/utils/files';
import { sortByUpdatedAt } from '@/utils/arrays';
import Title from '@/components/Title';
import {
  classes,
  isCollectionsShown,
  isFilesShown,
  isSequencesShown,
} from './utils';

export type FileCollectionProps = {
  isFetchingData?: boolean;
  parentFileCollectionID?: number;
  fileCollections: DecoratedFileCollection[] | null;
  files: UndecoratedFile[] | null;
  sequences: UndecoratedSequence[] | null;
  onOpenFileCollection: (fileCollectionID: number) => void;
  onDeleteFileCollection?: (fileCollectionID: number) => void;
  onDeleteFile?: (fileID: number) => void;
  onDeleteSequence?: (sequenceID: number) => void;
};

export default function FileCollection({
  isFetchingData,
  fileCollections,
  files,
  sequences,
  onOpenFileCollection,
  onDeleteFileCollection,
  onDeleteFile,
  onDeleteSequence,
}: FileCollectionProps) {
  const { onApiError } = useAppState();
  const [isDownloadToasterOpen, toggleDownloadToaster] = useToggle(false);
  const [isPreparingDownload, togglePreparingDownload] = useToggle(false);
  const [downloadMessage, setDownloadMessage] = useState(
    'Preparing download...'
  );

  const handleDownloadFile = async (fileID: number) => {
    try {
      togglePreparingDownload(true);
      toggleDownloadToaster(true);
      const selectedFile = files!.find((f) => f.id === fileID)!;
      const fileVersion = await fetchFileVersion(fileID);
      if (!fileVersion) return showErrorToaster('File version not found');
      await openFileDownloadLink(fileVersion.id, selectedFile.name);
      togglePreparingDownload(false);
    } catch (err) {
      handleCloseDownloadToaster();
      onApiError(err, 'Error downloading file');
    }
  };

  const handleDownloadCollection = async (collectionID: number) => {
    try {
      togglePreparingDownload(true);
      toggleDownloadToaster(true);
      await downloadFileCollection(collectionID, setDownloadMessage);
      togglePreparingDownload(false);
    } catch (err) {
      handleCloseDownloadToaster();
      onApiError(err, 'Error downloading collection');
    }
  };

  const handleCloseDownloadToaster = () => {
    togglePreparingDownload(false);
    toggleDownloadToaster(false);
  };

  return (
    <>
      <div className={classes.content}>
        <div className={classes.sectionWrapper}>
          <div className={classes.titleWrapper}>
            <Title size="sm">Sequences</Title>
          </div>
          <CollectionList isFetchingData={isFetchingData}>
            {isSequencesShown(sequences) ? (
              sortByUpdatedAt(sequences!).map((s) => (
                <SequenceListItem
                  key={s.id}
                  sequence={s}
                  onDelete={onDeleteSequence}
                />
              ))
            ) : (
              <div className={classes.empty}>No sequences.</div>
            )}
          </CollectionList>
        </div>
        <div className={classes.sectionWrapper}>
          <div className={classes.titleWrapper}>
            <Title size="sm">Files</Title>
          </div>
          <CollectionList isFetchingData={isFetchingData}>
            {isCollectionsShown(fileCollections) &&
              sortByUpdatedAt(fileCollections!).map((fc) => (
                <FileCollectionListItem
                  key={fc.id}
                  fileCollection={fc}
                  onOpen={onOpenFileCollection}
                  onDownload={handleDownloadCollection}
                  onDelete={onDeleteFileCollection}
                />
              ))}
            {isFilesShown(files) &&
              sortByUpdatedAt(files!).map((f) => (
                <FileListItem
                  key={f.id}
                  file={f}
                  onDownload={handleDownloadFile}
                  onDelete={onDeleteFile}
                />
              ))}
            {!isCollectionsShown(fileCollections) && !isFilesShown(files) && (
              <div className={classes.empty}>No files.</div>
            )}
          </CollectionList>
        </div>
      </div>
      <DownloadToaster
        open={isDownloadToasterOpen}
        isLoading={isPreparingDownload}
        message={downloadMessage}
        onClose={handleCloseDownloadToaster}
      />
    </>
  );
}

import type {
  UndecoratedFile,
  UndecoratedFileCollection,
  UndecoratedSequence,
} from '@witmetrics/api-client';

export const classes = {
  content: 'grow flex flex-col min-h-0 overflow-auto',
  titleWrapper: 'w-full flex justify-start items-center mb-1',
  sectionWrapper: 'px-4 mb-4',
  empty:
    'w-full h-full flex justify-center items-start py-2 text-lg text-grey-6 font-normal',
};

export function isCollectionsShown(
  fileCollections: UndecoratedFileCollection[] | null
) {
  return !!fileCollections && fileCollections.length > 0;
}

export function isFilesShown(files: UndecoratedFile[] | null) {
  return !!files && files.length > 0;
}

export function isSequencesShown(sequences: UndecoratedSequence[] | null) {
  return !!sequences && sequences.length > 0;
}
